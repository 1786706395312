import * as Sentry from "@sentry/nuxt";
import { fullStoryIntegration } from "@sentry/fullstory";
import { FullStory } from "@fullstory/browser";

Sentry.init({
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: useRuntimeConfig().public.SENTRY_CLIENT_DSN as string,
  integrations: [
    // Add browser profiling integration to the list of integrations
    Sentry.browserProfilingIntegration(),
    fullStoryIntegration(useRuntimeConfig().public.SENTRY_CLIENT_DSN as string, { client: FullStory }),
  ],
  environment: useRuntimeConfig().public.SENTRY_ENV,
  enabled: ["QA", "PROD", "DEVQA"].includes(String(useRuntimeConfig().public.SENTRY_ENV)),
  tracesSampleRate: 0.2,
  trackComponents: true,
  profilesSampleRate: 1,
});
